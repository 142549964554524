<template>
  <div>
    <el-card style="height: 880px">
      <div class="additem-title f14">
        <span>发布求购</span>
      </div>
      <div class="mt-30">
        <el-form
          ref="form"
          :rules="rules" 
          :model="form"
         hide-required-asterisk
          label-width="82px"
          class="flex"
        >
          <div style="margin-right: 84px">
            <el-form-item label="联系人" prop="linkman">
              <el-input
                v-model="form.linkman"
                placeholder="请输入联系人称呼"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="请输入联系方式"
              ></el-input>
            </el-form-item>
            <el-form-item label="求购产品">
              <el-select v-model="form.type" placeholder="请选择求购产品">
                <el-option label="专利" value="1"></el-option>
                <el-option label="商标" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-show="form.type == 1"
              prop="product_type"
              label="专利行业"
            >
              <el-select
                v-model="form.product_type"
                placeholder="请选择专利行业"
              >
                <el-option label="生活日用" value="生活日用"></el-option>
                <el-option label="机械工程" value="机械工程"></el-option>
                <el-option label="固定建筑" value="固定建筑"></el-option>
                <el-option label="物理运输" value="物理运输"></el-option>
                <el-option label="电学" value="电学"></el-option>
                <el-option label="物理" value="物理"></el-option>
                <el-option label="纺织制造" value="纺织制造"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-show="form.type == 1"
              prop="product_status"
              label="专利状态"
            >
              <el-select
                v-model="form.product_status"
                placeholder="请选择专利状态"
              >
                <el-option label="即将授权" value="即将授权"></el-option>
                <el-option label="授权未缴费" value="授权未缴费"></el-option>
                <el-option label="等专利回复" value="等专利回复"></el-option>
                <el-option label="已下证" value="已下证"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-show="form.type == 2"
              prop="product_type"
              label="商标类别"
            >
              <el-input
                v-model="form.product_type"
                placeholder="请输入商标分类"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-show="form.type == 2"
              prop="product_status"
              label="商标状态"
            >
              <el-input
                v-model="form.product_status"
                placeholder="请输入商标状态"
              ></el-input>
            </el-form-item>
            <el-form-item label="其他要求" prop="demand">
              <el-input
                v-model="form.demand"
                type="textarea"
                placeholder="请对其商标或专利进行详细描述"
                :rows="7"
                style="height: 160px"
              ></el-input>
            </el-form-item>
          </div>
          <div></div>
        </el-form>
      </div>
      <div class="flex">
       <div class="submit mt-30" @click="$router.go(-1)">返回</div>
      <div class="submit mt-30" @click="goBuy">立即提交</div>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  created(){
    this.order_no=this.$route.query.order_no||''
    if(this.order_no){
      this.form=this.$route.query
    }
   
  },
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入联系方式"));
      } else {
        var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (!myreg.test(value)) {
          callback(new Error("手机格式不正确"));
        } else {
          callback();
        }
      }
    };
    return {
      form: {
        linkman: "",
        phone: "",
        type: "1",
        product_type: "",
        product_status: "",
        demand: "",
        order_no:'',
        id:'',
      },
      rules: {
        linkman: [
          { required: true, message: "请输入联系人称呼", trigger: "blur" },
        ],
        phone: [{ validator: validatePhone, trigger: "blur" }],
        product_type: [
          { required: true, message: "所需行业或分类", trigger: "blur" },
        ],
        product_status: [
          { required: true, message: "所需状态", trigger: "blur" },
        ],
        demand: [
          {
            required: true,
            message: "请对其商标或专利进行详细描述",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods:{
    goBuy(){
      this.$refs.form.validate(res=>{
        if(res){
        this.$request.post('/client/transaction/buyOrdersAdd',{...this.form}).then(res=>{
        })

        }
      })
    }
    
  }
};
</script>
<style scoped>
.additem-title {
  border-bottom: 1px solid #e6e6e6;
}
.additem-title span {
  font-weight: 700;
  display: inline-block;
  border-bottom: 2px solid #2962ff;
  padding: 10px;
}
.el-input {
  height: 32px;
  width: 320px;
}
.el-card >>> .el-input__inner {
  height: 32px;
     border-color: #bbb;

}
.el-card >>>.el-textarea__inner{
   border-color: #bbb;
}
.el-card >>>.el-textarea__inner:focus{
   border-color: #2962ff;
}
.el-card >>> .el-input__inner:focus {
  border-color: #2962ff;
}
.el-select {
  width: 320px;
}
.el-form-item .el-form-item__label {
  color: #000 !important;
}
.el-card >>> .el-upload-dragger {
  width: 320px;
}
.submit {
  width: 124px;
  height: 48px;
  opacity: 1;
  background: #2962ff;
  border-radius: 8px;
  margin-left: 76px;
  line-height: 48px;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
</style>
